<section class="heading_banner">
  <div class="about_back">
    <img src="assets/img/asz/about_banner_back.png" class="about_back_img" />
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-lg-6">
        <div class="about_banner_content">
          <h3>
            <!-- <span>Grow</span> your Business & Customer
            <span>Satisfaction</span> with ASZ -->
             Driven by <span>design</span> excellence
          </h3>
          <p>
            <!-- diverse teams of experts combine innovative thinking and breakthrough technologies & enable our clients adapt and transform -->
            At ASZ, design is not just something we do – it is our way of life. We are united by a vision to deliver user-centric designs With a powerful team of UX enthusiasts, we solve user experience challenges and help businesses transform their work through design. 
          </p>
          <!-- <button class="process btn_process">Process</button> <button class="process serv btn_serv">Services</button> -->
        </div>
      </div>
      <div class="col-md-12 col-lg-6">
        <div class="about_banner_img">
          <img src="../../../assets/img/asz/about_top.jpg" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section class="heading_banner">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="banner_content">
          <h2>Grow your Business & Customer Satisfaction with ASZ</h2>
          <p>
            Dynamically disintermediate technically sound technologies with
            compelling quality vectors error-free communities.
          </p>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section class="presence">
  <div class="container">
    <div class="row">
      <div class="col-6 col-md-3 mobile-20">
        <div class="presence_blog rounded-top-0">
          <i class="fa-solid fa-calendar-days"></i>
          <p>Years of Presence</p>
          <h2>11</h2>
        </div>
      </div>
      <div class="col-6 col-md-3 mobile-20">
        <div class="presence_blog">
          <i class="fa-solid fa-face-smile"></i>
          <p>Happy Customers</p>
          <h2>60+</h2>
        </div>
      </div>
      <div class="col-6 col-md-3 mobile-20">
        <div class="presence_blog">
          <i class="fa-solid fa-list-check"></i>
          <p>Projects Delivered</p>
          <h2>250+</h2>
        </div>
      </div>
      <div class="col-6 col-md-3 mobile-20">
        <div class="presence_blog rounded-bottom-0">
          <i class="fa-solid fa-earth-americas"></i>
          <p>Countries Served</p>
          <h2>10+</h2>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="security-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 mobile-order2 col-md-12">
        <div class="about_image_about">
          <!-- <img src="../../../assets/img/asz/about-img-bg.JPG"> -->

          <picture>
            <source media="(max-width:992px)" srcset="../../../assets/img/asz/mobile-about-img-bg.png">
            <img src="../../../assets/img/asz/about-img-bg.JPG">
          </picture>
        </div>
      </div>
      <div class="col-lg-3 col-md-12">
        <div class="about_content_about">
          
            <div
              class="explore_offering"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-easing="ease-out-cubic"
            >
              <h2><span> About </span> ASZ</h2>
            </div>

            
            
          
        </div>
      </div>

      <div class="col-lg-5 mobile-order3 col-md-12">
        <div class="pera_about_pera">
         <h3>WHO WE ARE</h3>
<p>ASZ Technologies specializes in Digital Transformation Services that includes Sourcing and Advisory, 
  Market Intelligence, Industry 4.0, Data Science and Enterprise Data Management. We work in a collaborative 
  way with our customers by implementing business 
  requirements from strategy to launch as quickly and effectively as possible.</p>

  <p>Our diverse teams of experts combine innovative thinking and breakthrough technologies & enable our 
    clients adapt and transform, and together we achieve enduring results.</p>

  <p>ASZ Technologies currently has offices in India, Singapore and the United States and is continuing 
    to expand its presence in other countries. The company is the partner of choice to regional 
    Multi-National Companies looking for cloud and software as a service (SaaS) infrastructure, system 
    integration (SI) and cloud on-boarding. </p>
        </div>
      </div>

      
    </div>
  </div>
</section>

<!-- <section class="why-choose-us ptb-120">
  <div class="container">
      <div class="row justify-content-lg-between align-items-center">
          <div class="col-lg-6 col-12 order-lg-2">
            <div class="meet-our">
              <h4>ASZ Technologies</h4>
              <p>
                ASZ Technologies is a well-established software development company and one of the leading Information Technology providers. Based in Bangalore, India ASZ Technologies is home to a dedicated team of skilled IT professionals who are ready to serve your software development needs with customized solutions irrespective of the size of your company.
              </p>
            </div>
          </div>
          <div class="col-lg-5 col-12 order-lg-1">
              <div class="feature-img-holder mt-4 mt-lg-0 mt-xl-0">
                  <img src="assets/img/screen/widget-11.png" class="img-fluid" alt="feature-image">
              </div>
          </div>
      </div>
  </div>
</section> -->

<!-- <section class="team d-none d-lg-block">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div class="meet-our">
          <h4>Meet Our <br />Leadership Team</h4>
          <p>
            Our forward-looking leadership team is made up of dedicated, focused
            and experienced executives.
          </p>
        </div>
      </div>

      <div class="col-md-8 carousel-sty">
        <div class="row align-items-center">
          <carousel
            [height]="330"
            [margin]="5"
            [autoplay]="true"
            [cellsToShow]="2"
            [loop]="true"
            [borderRadius]="16"
          >
            <div class="carousel-cell">
              <div class="position-relative">
                <div
                  class="card-syl cta-card rounded-custom text-center p-3 bg-white mt-4 mt-lg-0 mt-md-0 z-2"
                >
                  <div
                    class="pro-user d-inline-block bg-primary-soft rounded-circle"
                  >
                    <img src="../../../assets/img/asz/avtar-img1.png">
                  </div>
                  <h6 class="h6">Althaf Hussain</h6>
                  <p class="mb-0 designation">Director</p>

                  <div class="single-col text-start text-lg-end text-md-end">
                    <ul
                      class="list-unstyled list-inline social-list mb-0 text-center"
                    >
                      <li class="list-inline-item">
                        <a><i class="fa-brands fa-linkedin-in"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="carousel-cell">
              <div class="position-relative">
                <div
                  class="card-syl cta-card rounded-custom text-center p-3 bg-white mt-4 mt-lg-0 mt-md-0 z-2"
                >
                  <div
                    class="pro-user d-inline-block bg-primary-soft rounded-circle"
                  >
                    <img src="../../../assets/img/asz/avtar-img2.png">
                  </div>
                  <h6 class="h6">Zakeer Hussain</h6>
                  <p class="mb-0 designation">Director</p>

                  <div class="single-col text-start text-lg-end text-md-end">
                    <ul
                      class="list-unstyled list-inline social-list mb-0 text-center"
                    >
                      <li class="list-inline-item">
                        <a><i class="fa-brands fa-linkedin-in"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</section> -->

<!-- <section class="team d-lg-none">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <div class="meet-our">
          <h4>Meet Our <br />Leadership Team</h4>
          <p>
            Our forward-looking leadership team is made up of dedicated, focused
            and experienced executives.
          </p>
        </div>
      </div>

      <div class="col-md-9 carousel-sty">
        <div class="row align-items-center">
          <carousel
            [height]="330"
            [margin]="5"
            [autoplay]="true"
            [cellsToShow]="1"
            [loop]="true"
            [borderRadius]="16"
          >
            <div class="carousel-cell">
              <div class="position-relative">
                <div
                  class="card-syl cta-card rounded-custom text-center p-3 bg-white mt-4 mt-lg-0 mt-md-0 z-2"
                >
                  <div
                    class="pro-user d-inline-block bg-primary-soft rounded-circle"
                  >
                    <i class="fa-solid fa-user-tie"></i>
                  </div>
                  <h6 class="h6">Althaf Hussain</h6>
                  <p class="mb-0 designation">Director</p>

                  <div class="single-col text-start text-lg-end text-md-end">
                    <ul
                      class="list-unstyled list-inline social-list mb-0 text-center"
                    >
                      <li class="list-inline-item">
                        <a><i class="fa-brands fa-linkedin-in"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="carousel-cell">
              <div class="position-relative">
                <div
                  class="card-syl cta-card rounded-custom text-center p-3 bg-white mt-4 mt-lg-0 mt-md-0 z-2"
                >
                  <div
                    class="pro-user d-inline-block bg-primary-soft rounded-circle"
                  >
                    <i class="fa-solid fa-user-tie"></i>
                  </div>
                  <h6 class="h6">Zakeer Hussain</h6>
                  <p class="mb-0 designation">Director</p>

                  <div class="single-col text-start text-lg-end text-md-end">
                    <ul
                      class="list-unstyled list-inline social-list mb-0 text-center"
                    >
                      <li class="list-inline-item">
                        <a><i class="fa-brands fa-linkedin-in"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section class="security-area leadership-outer">
  <div class="container">
    <div class="explore_offering meet-header">
      <h2><span> Meet </span> Our Team</h2>
    </div>
    <div class="nav-outer">
    <!-- <ul ngbNav #nav="ngbNav" [(activeId)]="active" [destroyOnHide]="false" class="nav-tabs"> -->
      <!-- <li [ngbNavItem]="1" [destroyOnHide]="true">
        <a ngbNavLink>Director</a>
        <ng-template ngbNavContent>
          <div class="leadership-row-main">
            <div class="leadership-col-main">
              <div class="images-circle">
              <img src="../../../assets/img/testimonial/pic4.jpg" class="img-fluid w-100">
              </div>
              <div class="leadership-cont">
                <h6>Althaf Hussain</h6>
                <p>Director</p>
              </div>
            </div>
            <div class="leadership-col-main">
              <div class="images-circle">
              <img src="../../../assets/img/testimonial/pic1.jpg" class="img-fluid w-100">
              </div>
              <div class="leadership-cont">
                <h6>Dr. Zakeer Hussain</h6>
                <p>Director</p>
              </div>
            </div>
          </div>
        </ng-template>
      </li> -->
      <!-- <li [ngbNavItem]="2">
        <a ngbNavLink>Front-End Developer</a>
        <ng-template ngbNavContent>
          <div class="leadership-row-main">
            <div class="leadership-col-main">
              <div class="images-circle">
              <img src="../../../assets/img/testimonial/pic1.jpg" class="img-fluid w-100">
              </div>
              <div class="leadership-cont">
                <h6>Sohil Mansuri</h6>
                <p>Front-End Developer</p>
              </div>
            </div>
            <div class="leadership-col-main">
              <div class="images-circle">
              <img src="../../../assets/img/testimonial/pic2.jpg" class="img-fluid w-100">
              </div>
              <div class="leadership-cont">
                <h6> Prashanth Sutradhar</h6>
                <p>Front-End Developer</p>
              </div>
            </div>
            <div class="leadership-col-main">
              <div class="images-circle">
              <img src="../../../assets/img/testimonial/pic3.jpg" class="img-fluid w-100">
              </div>
              <div class="leadership-cont">
                <h6>Thajuddin</h6>
                <p>Front-End Developer</p>
              </div>
            </div>
            <div class="leadership-col-main">
              <div class="images-circle">
              <img src="../../../assets/img/testimonial/pic4.jpg" class="img-fluid w-100">
              </div>
              <div class="leadership-cont">
                <h6>Ajay Singh</h6>
                <p>Front-End Developer</p>
              </div>
            </div>
            <div class="leadership-col-main">
              <div class="images-circle">
              <img src="../../../assets/img/testimonial/pic2.jpg" class="img-fluid w-100">
              </div>
              <div class="leadership-cont">
                <h6>Rashid Hassain</h6>
                <p>Front-End Developer</p>
              </div>
            </div>
            <div class="leadership-col-main">
              <div class="images-circle">
              <img src="../../../assets/img/testimonial/pic1.jpg" class="img-fluid w-100">
              </div>
              <div class="leadership-cont">
                <h6>Syeed Ameen Abrar</h6>
                <p>Front-End Developer</p>
              </div>
            </div>
            <div class="leadership-col-main">
              <div class="images-circle">
              <img src="../../../assets/img/testimonial/pic3.jpg" class="img-fluid w-100">
              </div>
              <div class="leadership-cont">
                <h6>Keshava Banda</h6>
                <p>Front-End Developer</p>
              </div>
            </div>
          </div>
        </ng-template>
      </li> -->
        <!-- <a ngbNavLink>All</a> -->
          <div
            class="search-results"
            infiniteScroll
            [infiniteScrollDistance]="1"
            [infiniteScrollThrottle]="50"
            (scrolled)="onScroll()"
          >
            <div class="leadership-row-main">
              <div class="leadership-col-main" *ngFor="let item of members">
                <div class="images-circle">
                <img src={{item.img}} class="img-fluid w-100">
                </div>
                <div class="leadership-cont">
                  <h6>{{item.name}}</h6>
                  <p>{{item.desgnation}}</p>
                </div>
              </div>
            </div>
            
          </div>
          <div class="loader-main">
            <loading *ngIf="isShowLoader"></loading>
          </div>
          
    <!-- </ul> -->
  </div>
    <!-- <div [ngbNavOutlet]="nav" class="mt-2"></div> -->
  </div>
</section>
